import { createSlice } from '@reduxjs/toolkit'
import { initialSalesStore } from 'store/sales/initial.ts'
import { getSalesUserData } from 'store/sales/thunks'

export const salesSlice = createSlice({
  name: 'sales',
  initialState: initialSalesStore,
  reducers: {
    clearSalesStore: () => initialSalesStore
  },
  extraReducers: (builder) => {
    // IS PENDING
    builder.addCase(getSalesUserData.pending, (state) => {
      return {
        ...state,
        loading: true,
        error: null
      }
    })

    // IS FULFILLED
    builder.addCase(getSalesUserData.fulfilled, (state, { payload }) => {
      return {
        ...state,
        ...payload,
        loading: false,
        error: null
      }
    })

    // IS REJECTED
    builder.addCase(getSalesUserData.rejected, (state, { payload }) => {
      return {
        ...state,
        error: payload || null,
        loading: false
      }
    })
  }
})

export const { clearSalesStore } = salesSlice.actions

export default salesSlice.reducer
