import styles from '../Navigation.module.scss'
import NavigationItem from '../navigation-item/NavigationItem.tsx'
import { routes } from 'types/routes.ts'
import { useTranslation } from 'react-i18next'
import { useStoreDispatch, useStoreSelector } from 'hooks/store.ts'
import Link from 'components/Link/Link.tsx'
import { Language } from 'store/app/types.ts'
import classNames from 'classnames'
import { Button, Heading } from '@boltenergy-be/design-system'
import LanguageSwitchButton from 'components/LanguageSwitchButton/LanguageSwitchButton.tsx'
import { determineAccessRights } from 'utils/contracts.ts'
import { logout } from 'utils/app.ts'
import { setUserType } from 'utils/userType.ts'
import { getUserTypeLoginPage } from 'utils/user.ts'
import { useNavigate } from 'react-router'
import useWindowSize from 'hooks/useWindowSize.tsx'
import { SMALL_DESKTOP_BREAKPOINT } from 'constants/viewport.ts'
import { useEffect } from 'react'
import { useGetActiveReferralActionQuery } from 'store/queries/cms-api'
import { resetContractsStore } from 'store/contracts/slice.ts'

const MoreNavigation = () => {
  // Redux
  const { language } = useStoreSelector((store) => store.app)
  const { userType } = useStoreSelector((store) => store.auth)
  const { selected, accounts, billingContracts, isProducer } = useStoreSelector((store) => store.contact)
  const dispatch = useStoreDispatch()

  // Referral action
  const { data: activeAction } = useGetActiveReferralActionQuery({ language })

  // i18n
  const { t } = useTranslation()

  // Window size
  const { isSmaller } = useWindowSize(SMALL_DESKTOP_BREAKPOINT)

  // Router
  const navigate = useNavigate()

  // Access rights
  const accessRights = determineAccessRights(billingContracts?.[selected.billingContract]?.serviceContracts?.electricity)

  /**
   * Handles the logout with correct redirect
   */
  const handleLogout = () =>
    logout(() => {
      setUserType(null)
      navigate(getUserTypeLoginPage(userType))
    })

  // Redirect to overview if not desktop
  useEffect(() => {
    if (!isSmaller) {
      navigate(routes.OVERVIEW, { replace: true })
    }
  }, [isSmaller])

  return (
    <nav className={classNames(styles.navigation, styles['more-navigation'])} aria-labelledby="main-more">
      <header className={styles.header}>
        <Heading as="h1" variant="h3">
          {t('nav.more', { ns: 'common' })}
        </Heading>
        <LanguageSwitchButton />
      </header>

      {accounts?.[selected.account] && (
        <div className={styles['account-info-card']}>
          <strong>{accounts[selected.account].name}</strong>
          <small className="text-subdued">
            {accounts[selected.account].billingContracts.length === 1
              ? selected.account
              : `${accounts[selected.account].billingContracts.length} ${t('nav.addressCard.addresses')}`}
          </small>
          {accounts && Object.keys(accounts).length > 1 && (
            <Button
              representation="link"
              onClick={() => {
                dispatch(resetContractsStore())
                navigate(routes.ACCOUNT_SELECTION)
              }}
            >
              {t('nav.addressCard.switchContract')}
            </Button>
          )}
        </div>
      )}

      <ul className={styles['nav-items-list']}>
        {accessRights?.marketPrices?.canAccess && (
          <NavigationItem isMore icon="euro" text={t('nav.marketPrices')} url={routes.MARKET_PRICES} />
        )}
        {!isProducer && <NavigationItem isMore icon="home" text={t('nav.move')} url={routes.MOVE} />}
        {!isProducer && (
          <NavigationItem
            isMore
            icon="peopleHeart"
            text={t('nav.friends')}
            url={routes.REFERRAL_INFO}
            tag={
              activeAction
                ? {
                    text: t('action'),
                    color: 'lightblue'
                  }
                : undefined
            }
          />
        )}
        <NavigationItem
          isMore
          icon="electricity"
          text={t(`nav.${isProducer ? 'myProducerProfile' : 'myProducer'}`)}
          url={routes.PRODUCER}
        />
        {isProducer && <NavigationItem isMore icon="badgeCheck" text={t('nav.guaranteeOfOrigin')} url={routes.GUARANTEE_OF_ORIGIN} />}
        {!isProducer && <NavigationItem isMore icon="thumbsUp" text={t('nav.energyOverview')} url={routes.ENERGY_OVERVIEW} />}
        <NavigationItem isMore icon="home" text={t('nav.myAddresses')} url={routes.ADDRESSES} />
        <NavigationItem isMore icon="userCircle" text={t('nav.user')} url={routes.USER_PERSONAL} />
        <NavigationItem isMore icon="speechQuestion" text={t('nav.support')} url={routes.SUPPORT_CONTACT} />
        <NavigationItem isMore icon="logOut" text={t('nav.logout')} onClick={handleLogout} id="logout" />
      </ul>

      <ul className={styles['legal-list']}>
        <li>
          <Link
            size="small"
            variant="tertiary"
            external
            hideExternalIcon
            href={`https://www.boltenergie.be/${language === Language.FR ? 'fr/termes-et-conditions' : 'nl/algemene-voorwaarden'}`}
          >
            {t('footer.terms')}
          </Link>
        </li>
        <li>
          <Link
            size="small"
            variant="tertiary"
            external
            hideExternalIcon
            href={`https://www.boltenergie.be/${
              language === Language.FR ? 'fr/mentions-legales-politique-de-confidentialite' : 'nl/disclaimer-privacy-policy'
            }`}
          >
            {t('footer.disclaimer')}
          </Link>
        </li>
      </ul>
    </nav>
  )
}

export default MoreNavigation
