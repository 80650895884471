import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import FormButtons from 'features/contracts/components/form-buttons/FormButtons'
import { EMAIL, LETTERS_NUMBERS_SLASHES_DASHES } from 'constants/regex'
import { isValidPostalCode } from 'utils/addContract'
import { getTown } from 'api/addContract'
import { Towns } from 'api/types'
import { UserTypes } from 'store/auth/types'
import { AddContractStepProps, AddContractSteps, AddressFormInputs } from '../../types.ts'
import { UrlSearchParamsKeys } from 'store/app/types'
import { getCustomersForSalesPartner } from 'store/sales/thunks'
import { useStoreDispatch, useStoreSelector } from 'hooks/store'
import { useGetSalesEventsQuery } from 'store/queries/bolt-api/sales'
import { Form, Heading, Select, TextField, Toggle } from '@boltenergy-be/design-system'
import { ELProduct, NGProduct, Product, ProductConfigOption } from 'types/products'
import { getProductFromProductCode } from 'utils/products'
import { PRODUCTS_CONFIG } from 'constants/products'
import { useGetProductContentQuery } from 'store/queries/cms-api'
import { ContractFlowTrackingTypes } from 'types/tracking.ts'
import { SimulationType } from 'types/contacts.ts'
import styles from './AddressStep.module.scss'
import contractStyles from '../../../Contracts.module.scss'
import classNames from 'classnames'

const AddressStep = ({ setNextStep, addContractData, setAddContractData, handleEanLookup, isSales, isMove }: AddContractStepProps) => {
  // Redux store
  const { userType } = useStoreSelector((store) => store.auth)
  const { urlSearchParams, language } = useStoreSelector((store) => store.app)
  const sales = useStoreSelector((store) => store.sales)
  const dispatch = useStoreDispatch()

  // Redux queries
  const {
    data: eventsData,
    isLoading,
    isError
  } = useGetSalesEventsQuery({ filterSignatures: true }, { skip: userType !== UserTypes.SALES })
  const { data: productsContent } = useGetProductContentQuery({ language })

  // i18n
  const { t } = useTranslation(['contracts', 'validation', 'common'])

  // Local state
  const [loading, setLoading] = useState<boolean>(false)
  const [towns, setTowns] = useState<Towns>([])

  // React Hook Form
  const hookForm = useForm<AddressFormInputs>({
    mode: 'onBlur',
    defaultValues: {
      ...addContractData[AddContractSteps.ADDRESS],
      ...(urlSearchParams[UrlSearchParamsKeys.CUSTOMER_EMAIL] && {
        salesEmail: urlSearchParams[UrlSearchParamsKeys.CUSTOMER_EMAIL]
      })
    }
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    trigger
  } = hookForm
  const watchTownName = watch('deliveryAddress.townName')
  const watchPostalCode = watch('deliveryAddress.postalCode')
  const watchNeedsGas = watch('needsGas')
  const watchEventId = watch('eventId')

  // Constants
  const event = watchEventId ? eventsData?.events?.find((event) => event._id === watchEventId) : undefined

  /**
   * update the instalmentData if the sales event has simulationType.BOLT_GO
   */
  useEffect(() => {
    if (
      isSales &&
      event?.parameters?.simulation_type === SimulationType.BOLT_GO &&
      addContractData[AddContractSteps.INSTALMENT_DATA].simulationType !== SimulationType.BOLT_GO
    ) {
      setAddContractData({
        ...addContractData,
        [AddContractSteps.INSTALMENT_DATA]: {
          ...addContractData[AddContractSteps.INSTALMENT_DATA],
          simulationType: SimulationType.BOLT_GO
        },
        [AddContractSteps.PRODUCT]: {
          electricity: ELProduct.Go,
          gas: addContractData[AddContractSteps.ADDRESS].needsGas ? NGProduct.Go : undefined
        }
      })
    }
  }, [event, addContractData, isSales, setAddContractData])

  /**
   * Triggered on first render
   */
  useEffect(() => {
    // Fetch partner customers if email is found in url search params store
    const customerEmail = urlSearchParams[UrlSearchParamsKeys.CUSTOMER_EMAIL]
    if (customerEmail) {
      getPartnerCustomers(customerEmail)
    }

    // Checks if town names should be fetched based on postalCode in store
    const postalCode = addContractData[AddContractSteps.ADDRESS].deliveryAddress.postalCode
    validatePostalCodeAndFetchTowns(typeof postalCode === 'string' ? parseInt(postalCode) : postalCode)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Handles the submit after validation by React Hook Form
   *
   * @param {AddressFormInputs} data
   */
  const onSubmit = async (data: AddressFormInputs) => {
    // Perform EAN lookup
    if (handleEanLookup) handleEanLookup(data.deliveryAddress)

    // Fetch the event & add it to the data
    if (event) {
      data.event = event
    }

    setNextStep(data, isMove ? AddContractSteps.REFERRAL : AddContractSteps.PRODUCER)
  }

  /**
   * Fetches the town names based on the given postalCode
   *
   * @param {number} postalCode
   */
  const validatePostalCodeAndFetchTowns = async (postalCode: number) => {
    if (isValidPostalCode(postalCode)) {
      const response = await getTown(postalCode)

      if (response !== null) {
        const { towns, region } = response
        setTowns(towns)

        if (!towns.some((town) => town.townName === watchTownName)) {
          setValue('deliveryAddress.townName', towns[0].townName)
          setValue('deliveryAddress.townCode', towns[0].townCode)
          setValue('region', region)
        }

        // Return error when Bolt Go is not available in the selected region
        if (
          isSales &&
          event?.parameters?.simulation_type === SimulationType.BOLT_GO &&
          !PRODUCTS_CONFIG[Product.GO][ProductConfigOption.AVAILABLE_IN_REGIONS].Push.includes(region)
        ) {
          return t('validation:invalid.productNotAvailable', {
            product: productsContent?.[Product.GO] ? `Bolt ${productsContent[Product.GO].name}` : t('common:thisProduct', 'Dit product')
          })
        }

        return true
      }
    }

    return t('validation:invalid.postalCode')
  }

  // Trigger postalCode validation on event change
  useEffect(() => {
    if (watchPostalCode && event) trigger('deliveryAddress.postalCode')
  }, [event, trigger])

  /**
   * Fetches customers for sales partners and adds email to CustomerData step
   * @param {string} email
   */
  const getPartnerCustomers = async (email: string) => {
    try {
      setLoading(true)
      if (sales?.partner?.id && !errors.salesEmail) {
        await dispatch(getCustomersForSalesPartner({ email, partnerId: sales.partner.id }))
        setAddContractData({
          ...addContractData,
          [AddContractSteps.CUSTOMER_DATA]: {
            ...addContractData[AddContractSteps.CUSTOMER_DATA],
            email,
            emailConfirmation: email
          }
        })
      }
    } finally {
      setLoading(false)
    }
  }

  /**
   * Handles the town select change & sets the town code
   *
   * @param {string} selectedTown
   */
  const handleTownSelectChange = (selectedTown: string) => {
    const town = towns.find((town) => town.townName === selectedTown)
    const townCode = town?.townCode ?? 0
    const townName = town?.townName ?? ''

    setValue('deliveryAddress.townCode', townCode)
    setValue('deliveryAddress.townName', townName)
  }

  return (
    <section className={styles['address-section']}>
      <Heading as="h1" variant="h4">
        {t('add.steps.address.title')}
      </Heading>
      <Form onSubmit={handleSubmit(onSubmit)} className={classNames(styles['address-form'], contractStyles['narrow-grid'])}>
        {isSales && !isLoading && !isError && (
          <>
            <TextField
              id="salesEmail"
              label={t('add.steps.customerData.fields.email')}
              {...register('salesEmail', {
                required: t('validation:required'),
                pattern: { value: EMAIL, message: t('validation:invalid.email') }
              })}
              onBlur={(e) => getPartnerCustomers(e.target.value)}
              error={errors?.salesEmail?.message}
            />

            <Select
              id="eventId"
              label="Sales event"
              {...register('eventId', { required: t('validation:required') })}
              error={errors?.eventId?.message}
            >
              {eventsData?.events.map((event) => (
                <option key={event._id} value={event._id}>
                  {event.eventId} | {event.eventName}
                </option>
              ))}
            </Select>
          </>
        )}

        {/* DELIVERY ADDRESS STREET */}
        <TextField
          id="deliveryAddress.streetName"
          label={t('add.steps.address.form.deliveryAddress.streetName')}
          {...register('deliveryAddress.streetName', { required: t('validation:required') })}
          error={errors?.deliveryAddress?.streetName?.message}
        />

        <TextField
          id="deliveryAddress.streetNumber"
          label={t('add.steps.address.form.deliveryAddress.streetNumber')}
          {...register('deliveryAddress.streetNumber', {
            required: t('required', { ns: 'validation' }),
            maxLength: { value: 5, message: t('validation:invalid.length', { length: 5 }) },
            pattern: { value: LETTERS_NUMBERS_SLASHES_DASHES, message: t('validation:invalid.streetNumber') }
          })}
          maxLength={5}
          error={errors?.deliveryAddress?.streetNumber?.message}
          grid="span 1"
        />

        <TextField
          id="deliveryAddress.streetBox"
          label={t('add.steps.address.form.deliveryAddress.streetBox')}
          {...register('deliveryAddress.streetBox', {
            maxLength: { value: 5, message: t('validation:invalid.length', { length: 5 }) },
            pattern: { value: LETTERS_NUMBERS_SLASHES_DASHES, message: t('validation:invalid.streetBox') }
          })}
          maxLength={5}
          error={errors?.deliveryAddress?.streetBox?.message}
          grid="span 1"
        />

        {/* DELIVERY ADDRESS POSTAL CODE */}
        <TextField
          id="deliveryAddress.postalCode"
          label={t('add.steps.address.form.deliveryAddress.postalCode')}
          {...register('deliveryAddress.postalCode', {
            required: t('validation:required'),
            validate: (val) => validatePostalCodeAndFetchTowns(parseInt(val as string))
          })}
          type="number"
          error={errors?.deliveryAddress?.postalCode?.message}
          inputMode="numeric"
          onChange={(e) => {
            const value = e.target.value
            setValue('deliveryAddress.postalCode', value)
            validatePostalCodeAndFetchTowns(parseInt(value))
          }}
        />

        {/* DELIVERY ADDRESS TOWN NAME */}
        <Select
          id="address.townName"
          label={t('add.steps.address.form.deliveryAddress.townName')}
          {...register('deliveryAddress.townName', {
            required: t('validation:required')
          })}
          value={watchTownName}
          error={errors?.deliveryAddress?.townName?.message}
          onChange={(e) => handleTownSelectChange(e.target.value)}
        >
          {towns.map((town) => (
            <option key={town.townCode} value={town.townName}>
              {town.townName}
            </option>
          ))}
        </Select>

        <div className={styles['toggle-group']}>
          <strong>{t('add.steps.address.form.fuelType.title')}</strong>
          <Toggle
            isFullwidth
            active={watchNeedsGas}
            options={[
              { label: t('add.steps.address.form.fuelType.labels.onlyElec'), value: false },
              { label: t('add.steps.address.form.fuelType.labels.elecAndGas'), value: true }
            ]}
            onClick={(needsGas) => {
              setValue('needsGas', needsGas)
              const product = getProductFromProductCode(addContractData[AddContractSteps.PRODUCT].electricity)
              setAddContractData({
                ...addContractData,
                [AddContractSteps.PRODUCT]: {
                  ...addContractData[AddContractSteps.PRODUCT],
                  gas: needsGas && product ? NGProduct[product] : undefined
                }
              })
            }}
          />
        </div>

        <FormButtons loading={loading} currentStep={AddContractSteps.ADDRESS} trackingId={ContractFlowTrackingTypes.ADD} />
      </Form>
    </section>
  )
}

export default AddressStep
