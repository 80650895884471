import dayjs from 'dayjs'
import {
  AddContractData,
  AddContractSteps,
  CounterTypes,
  CreateContractData,
  MeterType,
  OptinPoint,
  SimulationSalesOffice,
  Situation
} from '../../types'
import { fileToBase64 } from 'utils/files'
import { store } from 'store'
import { AuthStore, UserTypes } from 'store/auth/types'
import { PARTNER_IDS } from 'constants/constants'
import { SourceType } from 'types/user'
import { checkIsDynamicTariffEligible } from 'utils/smartBillingOptions.ts'
import { getProductFromProductCode } from 'utils/products.ts'
import { Region } from 'api/types'
import { removeNonAlphanumericals } from 'utils/format.ts'
import { PaymentMode, SignChannel, SimulationType } from 'types/contacts.ts'
import { getLowerCaseLanguage } from 'utils/app.ts'
import { IS_PROD_OR_BETA } from 'constants/envs.ts'

/**
 * Maps the data for the createContract request
 *
 * @param {AddContractData} addContractData
 * @returns {Promise<CreateContractData>}
 */
export const mapCreateContractData = async (addContractData: AddContractData): Promise<CreateContractData> => {
  // Get data from store
  const { userType } = store.getState().auth as AuthStore
  const { move } = store.getState().app
  const sales = store.getState().sales

  // Define needed constants
  const isSales = userType === UserTypes.SALES

  // Get data from passed props
  const { address, producer, instalmentData, meterDetails, customerData, referral } = addContractData

  // Check if producer is defined
  if (!producer) throw new Error('No producer defined')

  // Map & return the create contract data
  return {
    boltGoPrices:
      instalmentData.simulationType === SimulationType.BOLT_GO
        ? {
            electricity: addContractData[AddContractSteps.INSTALMENT_DATA].instalments.electricity,
            gas: addContractData[AddContractSteps.ADDRESS].needsGas ? addContractData[AddContractSteps.INSTALMENT_DATA].instalments.gas : 0
          }
        : undefined,
    ...(customerData.isCompany && {
      company: {
        name: customerData.companyName ?? '',
        vatApplication: customerData.subjectToVat || true,
        enterpriseNumber: customerData.companyNumber ?? '',
        legalForm: customerData.legalForm
      }
    }),
    contact: {
      contactMode: customerData.deliveryMode,
      email: customerData.email,
      phoneMobile: customerData.mobilePhone
    },
    contractStartDate: dayjs(meterDetails.contractStartDate).format('YYYY-MM-DD'),
    correspondenceAddress: customerData.correspondenceAddress ?? address.deliveryAddress,
    counterType: meterDetails.counterType,
    customerNumber: customerData.customerNumber,
    deliveryAddress: address.deliveryAddress,
    ...(meterDetails.docUpload && {
      document: (await fileToBase64(meterDetails.docUpload[0])) as string
    }),
    dynamicTariff: checkIsDynamicTariffEligible({
      meterDetails,
      needsGas: address.needsGas,
      product: getProductFromProductCode(addContractData[AddContractSteps.PRODUCT].electricity),
      region: address.region
    })
      ? meterDetails.dynamicTariff
      : false,
    eanCaptureType: meterDetails.eanCaptureType,
    hasSolarPanels: meterDetails.hasSolarPanels,
    ...(instalmentData.simulationType === SimulationType.FIXED_AMOUNT && {
      instalments: {
        electricity: instalmentData.instalments.electricity,
        ...(address.needsGas && { gas: instalmentData.instalments.gas })
      }
    }),
    invoicing: {
      paymentMethod: customerData.paymentMethod,
      ibanNumber: removeNonAlphanumericals(customerData.paymentMethod === PaymentMode.DOMICILATION ? (customerData.iban ?? '') : '')
    },
    language: getLowerCaseLanguage(customerData.language),
    marketing: {
      ...(userType !== UserTypes.CUSTOMER && {
        ...(isSales && { agentID: sales?.agentId }),
        signChannel: SignChannel.CONFIRMATION_MAIL,
        partnerID: isSales
          ? sales?.partner.id
          : IS_PROD_OR_BETA
            ? PARTNER_IDS.PRODUCTION_BOLT_SUPER_USER
            : PARTNER_IDS.STAGING_BOLT_SUPER_USER
      }),
      eanCaptureType: meterDetails.eanCaptureType,
      optinPoint: OptinPoint.PORTAL_REGISTRATION,
      simulationType: instalmentData.simulationType,

      ...(address.event
        ? {
            campaignName: address.event.parameters.campaign_name || undefined,
            eventID: address.event.eventId || undefined,
            eventName: address.event.eventName || undefined,
            promoCode: address.event.parameters.promocode || undefined,
            simulationSalesOffice: (address.event.parameters.simulation_so as string) || undefined,
            sourceType: (address.event.parameters.source_type as SourceType) || undefined
          }
        : {
            simulationSalesOffice:
              meterDetails.situation === Situation.MOVE ? SimulationSalesOffice.MOVE : SimulationSalesOffice.EXTRA_ADDRESS,
            sourceType: SourceType.PULL
          })
    },
    meterInfo: {
      electricity: {
        EAN: meterDetails.electricity.ean?.toString() ?? '',
        exclEAN: meterDetails.electricity.eanExclNight?.toString ?? '',
        indexDay:
          meterDetails.meterType === MeterType.SINGLE_RATE
            ? (meterDetails.electricity.total?.toString() ?? '')
            : (meterDetails.electricity.day?.toString() ?? ''),
        indexNight: meterDetails.electricity.night?.toString() ?? '',
        indexExcl: meterDetails.electricity.exclNight?.toString() ?? '',
        meterNumber: meterDetails.electricity.meterNumber?.toString() ?? ''
      },
      gas: {
        EAN: address.needsGas ? (meterDetails.gas?.ean?.toString() ?? '') : '',
        index: address.needsGas ? (meterDetails.gas?.total?.toString() ?? '') : '',
        meterNumber: address.needsGas ? (meterDetails.gas?.meterNumber?.toString() ?? '') : ''
      }
    },
    meterType: meterDetails.meterType,
    moveFlow: move.flow,
    person: {
      firstName: customerData.firstName,
      lastName: customerData.lastName,
      ...(customerData.dateOfBirth?.day &&
        customerData.dateOfBirth?.month &&
        customerData.dateOfBirth?.year && {
          birthDate: dayjs(`${customerData.dateOfBirth.year}-${customerData.dateOfBirth.month}-${customerData.dateOfBirth.day}`).format(
            'YYYY-MM-DD'
          )
        })
    },
    previousInhabitant: meterDetails.previousInhabitant,
    producer: {
      id: producer.id,
      name: producer.name,
      salesforceId: producer.salesforceId,
      slug: producer.slug,
      sourceRegion: producer.energyRegion
    },
    products: {
      electricity: addContractData[AddContractSteps.PRODUCT].electricity,
      gas: addContractData[AddContractSteps.PRODUCT].gas
    },
    referral,
    situation: meterDetails.situation,
    ...(address.region === Region.WALLONIA &&
      meterDetails.counterType === CounterTypes.DIGITAL && { walloniaSolarPanelsSince2024: meterDetails.walloniaSolarPanelsSince2024 })
  }
}
