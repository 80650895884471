import styles from '../Navigation.module.scss'
import NavigationItem from '../navigation-item/NavigationItem.tsx'
import { routes } from 'types/routes.ts'
import { clearUser } from 'utils/app.ts'
import { useTranslation } from 'react-i18next'
import { memo, useEffect, useRef } from 'react'
import { NavigationProps } from '../types.ts'
import { useStoreDispatch, useStoreSelector } from 'hooks/store.ts'
import { Button, Icon, Tag } from '@boltenergy-be/design-system'
import { UserTypes } from 'store/auth/types.ts'
import { useNavigate } from 'react-router'
import { useLocation } from 'react-router-dom'
import LanguageSwitchBar from 'components/LanguageSwitchBar/LanguageSwitchBar.tsx'
import { determineAccessRights } from 'utils/contracts.ts'
import useToggleWithClickOutside from 'hooks/useToggleWithClickOutside.ts'
import mixpanel from 'mixpanel-browser'
import { CommonTrackingEvents, NavigationTypes } from 'types/tracking.ts'
import { IS_BETA } from 'constants/envs.ts'
import Link from 'components/Link/Link.tsx'
import classNames from 'classnames'
import { resetContractsStore } from 'store/contracts/slice.ts'

const DesktopNavigation = memo(({ isSales, handleLogout, withAction }: NavigationProps) => {
  // Redux
  const { selected, accounts, billingContracts, isProducer, contact } = useStoreSelector((store) => store.contact)
  const { userType } = useStoreSelector((store) => store.auth)
  const dispatch = useStoreDispatch()

  // i18n
  const { t } = useTranslation()

  // React router
  const navigate = useNavigate()
  const { pathname } = useLocation()

  // Refs
  const dropdownRef = useRef<HTMLDivElement>(null)
  const { isOpen, setIsOpen } = useToggleWithClickOutside(dropdownRef)

  // Access rights
  const accessRights = determineAccessRights(billingContracts?.[selected.billingContract]?.serviceContracts?.electricity)

  /**
   * Closes dropdown on route change
   */
  useEffect(() => {
    if (pathname) setIsOpen(false)
  }, [pathname])

  return (
    <aside className={classNames(styles['navigation-wrapper'], { [styles.superuser]: userType === UserTypes.SUPER_USER })}>
      <LanguageSwitchBar compact sticky />

      <nav className={styles.navigation} aria-labelledby="main">
        {userType === UserTypes.SUPER_USER && (
          <ul className={styles['superuser-actions']}>
            <li>
              <Button
                leadingIcon="shuffle"
                variant="layered"
                onClick={() => {
                  clearUser()
                  navigate(routes.CUSTOMER_EMAIL)
                }}
              >
                Customer
              </Button>
            </li>
            <li>
              <Link representation="button" leadingIcon="menu" variant="layered" href={routes.EVENT_LOG}>
                Events
              </Link>
            </li>
          </ul>
        )}

        {!isSales && accounts?.[selected.account] && (
          <div ref={dropdownRef} className={styles.dropdown}>
            <button
              aria-expanded={isOpen}
              className={styles['account-info-card']}
              onClick={() => {
                setIsOpen(!isOpen)
                mixpanel.track(CommonTrackingEvents.CLICK_ACCOUNT_NAME, { type: NavigationTypes.MAIN })
              }}
            >
              <strong className="truncate">{accounts[selected.account].name}</strong>
              <small className="text-subdued">
                {selected.account}
                {accounts[selected.account].billingContracts.length > 1 &&
                  ` — ${accounts[selected.account].billingContracts.length} ${t('nav.addressCard.addresses')}`}
              </small>
              <Icon name="chevronDown" />

              {userType === UserTypes.SUPER_USER && contact && (
                <Tag size="small" className={styles['superuser-tag']} color="gray">
                  {contact?.dbId ? 'Account created' : 'Account not created'}
                </Tag>
              )}
            </button>

            <ul style={{ display: isOpen ? 'flex' : 'none' }} className={styles.list} aria-hidden={!isOpen}>
              {accounts && Object.keys(accounts).length > 1 && (
                <NavigationItem
                  id="switch-account"
                  icon="shuffle"
                  text={t('nav.switchAccount')}
                  onClick={() => {
                    dispatch(resetContractsStore())
                    navigate(routes.ACCOUNT_SELECTION)
                  }}
                />
              )}
              <NavigationItem id="logout" icon="logOut" text={t('nav.logout')} onClick={handleLogout} />
            </ul>
          </div>
        )}

        <ul className={styles['nav-items-list']}>
          {isSales ? (
            <>
              <NavigationItem icon="homeSmile" text={t('nav.overview')} url={routes.SALES_HOME} />
              <NavigationItem icon="file" text={t('nav.events')} url={routes.SALES_EVENTS} />
              {!IS_BETA && <NavigationItem icon="home" text={t('nav.address')} url={routes.SALES_CONTRACTS_ADD} />}
              <NavigationItem icon="logOut" text={t('nav.logout')} onClick={handleLogout} id="logout" />
            </>
          ) : (
            <>
              <NavigationItem icon="homeSmile" text={t('nav.overview')} url={routes.OVERVIEW} />
              <NavigationItem
                icon="charts"
                text={t(`nav.${isProducer ? 'injection' : 'usage'}`)}
                extraActiveRoutes={[routes.CONSUMPTION]}
                url={routes.CONSUMPTION_ELECTRICITY}
              />
              <NavigationItem icon="file" text={t('nav.billing')} extraActiveRoutes={[routes.BILLING]} url={routes.BILLING_INVOICES} />
              {accessRights?.marketPrices?.canAccess && (
                <NavigationItem icon="euro" text={t('nav.marketPrices')} url={routes.MARKET_PRICES} />
              )}
              {!isProducer && <NavigationItem icon="home" text={t('nav.move')} extraActiveRoutes={[routes.MOVE]} url={routes.MOVE} />}
              {!isProducer && (
                <NavigationItem
                  icon="peopleHeart"
                  text={t('nav.friends')}
                  extraActiveRoutes={[routes.REFERRAL]}
                  url={routes.REFERRAL_INFO}
                  tag={
                    withAction
                      ? {
                          text: t('action'),
                          color: 'lightblue'
                        }
                      : undefined
                  }
                />
              )}
              <NavigationItem icon="electricity" text={t(`nav.${isProducer ? 'myProducerProfile' : 'myProducer'}`)} url={routes.PRODUCER} />
              {isProducer && <NavigationItem icon="badgeCheck" text={t('nav.guaranteeOfOrigin')} url={routes.GUARANTEE_OF_ORIGIN} />}
              {!isProducer && <NavigationItem icon="thumbsUp" text={t('nav.energyOverview')} url={routes.ENERGY_OVERVIEW} />}
              <NavigationItem icon="home" text={t('nav.myAddresses')} url={routes.ADDRESSES} />
              <NavigationItem icon="userCircle" text={t('nav.user')} extraActiveRoutes={[routes.USER]} url={routes.USER_PERSONAL} />
              <NavigationItem
                icon="speechQuestion"
                text={t('nav.support')}
                extraActiveRoutes={[routes.SUPPORT]}
                url={routes.SUPPORT_CONTACT}
              />
            </>
          )}
        </ul>
      </nav>
    </aside>
  )
})

DesktopNavigation.displayName = 'DesktopNavigation'

export default DesktopNavigation
