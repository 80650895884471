import styles from './ContractsLayout.module.scss'
import classNames from 'classnames'
import { routes } from 'types/routes.ts'
import SeoTags from 'components/SeoTags/SeoTags.tsx'
import { useTranslation } from 'react-i18next'
import { PropsWithChildren } from 'react'
import { ContractsLayoutProps, FlowSteps } from 'features/contracts/layout/types.ts'
import { useStoreDispatch, useStoreSelector } from 'hooks/store.ts'
import { UserTypes } from 'store/auth/types.ts'
import { useLocation, useNavigate } from 'react-router'
import { Button } from '@boltenergy-be/design-system'
import { clearUser } from 'store/contact/slice.ts'
import mixpanel from 'mixpanel-browser'
import { AddContractSteps } from '../add/types'
import { TerminateSteps } from '../terminate/types'
import { ContractFlowEvents } from 'types/tracking.ts'

const ContractsLayout = <Steps extends FlowSteps | undefined>({
  flow,
  steps,
  currentStep,
  children,
  label,
  setCurrentStep,
  trackingId
}: PropsWithChildren<ContractsLayoutProps<Steps>>) => {
  // Redux
  const { userType } = useStoreSelector((store) => store.auth)
  const { move } = useStoreSelector((store) => store.app)
  const dispatch = useStoreDispatch()

  // i18n
  const { t } = useTranslation(['contracts', 'common'])

  // Router
  const navigate = useNavigate()
  const { pathname } = useLocation()

  // Constants
  const showNav = !!steps && currentStep !== steps[steps.length - 1]
  const stepsWithoutLast = steps?.slice(0, -1) || []
  const isSales = userType === UserTypes.SALES
  const isLastStep = steps?.[steps.length - 1] === currentStep

  /**
   * Handles exit button click
   */
  const handleExit = () => {
    if (userType === UserTypes.SALES) {
      dispatch(clearUser())
      navigate(routes.SALES_HOME)
    } else {
      navigate(routes.OVERVIEW)
    }
  }

  /**
   * Returns previous step based on the current step
   *
   * @return {string}
   */
  const getPreviousStep = (): Steps | undefined => {
    const currentIndex = steps?.findIndex((step) => step === currentStep)
    return currentIndex ? steps?.[currentIndex - 1] : undefined
  }

  return (
    <>
      <SeoTags title={t(`seo.${flow || 'index'}`)} />

      <header className={styles.header}>
        {pathname === routes.CONTRACTS || isLastStep ? (
          <div />
        ) : (
          <Button
            type="button"
            variant="tertiary"
            leadingIcon="arrowLeft"
            onClick={() => {
              mixpanel.track(ContractFlowEvents.PREVIOUS_STEP, {
                flow: move.flow,
                type: trackingId,
                currentStep
              })
              if (currentStep === AddContractSteps.ADDRESS || currentStep === TerminateSteps.CHURN) {
                if (isSales) {
                  navigate(routes.SALES_HOME)
                } else {
                  navigate(-1)
                }
              } else if (setCurrentStep) {
                const prevStep = getPreviousStep()
                if (prevStep) {
                  setCurrentStep(prevStep)
                }
              }
            }}
          />
        )}

        <div className={styles['step-description']}>
          {showNav ? <small>{label}</small> : <strong>{label}</strong>}

          {showNav && (
            <strong className={styles.indicator}>
              {t('header.indicator', {
                current: stepsWithoutLast.findIndex((step) => step === currentStep) + 1,
                total: stepsWithoutLast.length
              })}
            </strong>
          )}
        </div>

        <Button variant="tertiary" leadingIcon="cross" className={styles.exit} onClick={handleExit} />
      </header>

      <main className={classNames(styles.main, { [styles.sales]: userType === UserTypes.SALES })}>
        <div className={styles.wrapper}>{children}</div>
      </main>
    </>
  )
}

export default ContractsLayout
