import { Button, Drawer, Heading, List } from '@boltenergy-be/design-system'
import styles from './HowItWorks.module.scss'
import ActionCard from '../components/ActionCard/ActionCard'
import { Trans, useTranslation } from 'react-i18next'
import LinkAndShareCard from '../components/LinkAndShareCard/LinkAndShareCard'
import { useGetReferralCodeQuery } from 'store/queries/bolt-api/customers'
import { Language } from 'store/app/types'
import { getBoltWebsiteOrigin } from 'utils/origins'
import { useStoreSelector } from 'hooks/store'
import { selectContact } from 'store/contact/selectors'
import { DEFAULT_REFERRAL_AMOUNT } from 'constants/referral'
import { useGetActiveReferralActionQuery } from 'store/queries/cms-api'
import StepList from 'components/StepList/StepList.tsx'
import { useState } from 'react'

const HowItWorks = () => {
  // Redux
  const { language } = useStoreSelector((store) => store.app)
  const { contact } = useStoreSelector((store) => store.contact)
  const { email } = selectContact({ contact })

  // i18n
  const { t } = useTranslation('referral')

  // Redux queries
  const { data: activeAction } = useGetActiveReferralActionQuery({ language })
  const { data: referralCodeData, isError: isReferralCodeError } = useGetReferralCodeQuery({ email })

  // Local state
  const [showReferralTerms, setShowReferralTerms] = useState<boolean>(false)

  // Local constants
  const amount = activeAction?.attributes.reduction || DEFAULT_REFERRAL_AMOUNT
  const referralLink = `${getBoltWebsiteOrigin()}${
    language === Language.FR ? 'fr/convaincre-un-ami' : 'nl/overtuig-een-vriend'
  }?referralcode=${referralCodeData?.code ?? ''}`

  return (
    <section className={styles['how-it-works']}>
      <article className={styles.details}>
        {/* HOW IT WORKS */}
        <section className={styles['steps-card']}>
          <Heading as="h2" variant="h4" weight={400}>
            {t('howItWorks.heading')}
          </Heading>

          <StepList>
            {[1, 2, 3].map((bullet) => (
              <li key={bullet}>
                <strong>{t(`howItWorks.steps.${bullet as 1 | 2 | 3}.heading`, { amount })}</strong>
                <small>
                  <Trans
                    t={t}
                    i18nKey={`howItWorks.steps.${bullet as 1 | 2 | 3}.paragraph`}
                    values={{ amount }}
                    components={{
                      CustomButton: (
                        <Button representation="link" className={styles['inline-btn']} onClick={() => setShowReferralTerms(true)} />
                      )
                    }}
                  />
                </small>
              </li>
            ))}
          </StepList>
        </section>

        {/* SHARE */}
        <LinkAndShareCard
          amount={amount}
          shareLink={referralLink}
          hasError={isReferralCodeError}
          activeAction={activeAction || undefined}
        />
      </article>

      {/* ACTIVE ACTION CARD - THIS PART HAS NOT BEEN UPDATED */}
      {!!activeAction && <ActionCard {...{ activeAction }} />}

      <Drawer isOpen={showReferralTerms} onRequestClose={() => setShowReferralTerms(false)} title={t('howItWorks.drawer.title')}>
        <p>{t('howItWorks.drawer.description', { reward: amount })}</p>
        <List as="ul">
          {[1, 2, 3, 4].map((bullet) => (
            <li key={bullet}>{t(`howItWorks.drawer.bullets.${bullet.toString() as '1' | '2' | '3' | '4'}`)}</li>
          ))}
        </List>
      </Drawer>
    </section>
  )
}

export default HowItWorks
